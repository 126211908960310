/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    div: "div",
    blockquote: "blockquote",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "За замовчуванням docker зберігає усі імейджи, контейнери, вол’юми і т.д. в\nдирикторії ", React.createElement(_components.code, null, "/var/lib/docker"), " (розділ ", React.createElement(_components.code, null, "root"), "). Може такий підхід це ок для\nпродакшену, але це не дуже зручно для локальної розробки. Зазвичай, під ", React.createElement(_components.code, null, "root"), "\nрозділ виділяють не дуже багато місця. А докер, навіть якщо його не активно\nюзати, дуже легко може з’їсти 10-20гб. Та і взагалі мені контейнери більше\nвиглядають як залежності, типу бібліотек npm. Ми ж не зберігаємо пакети npm чи\nвихідний код наших проектів в root? Тому не бачу сенсу робити особливі\nвиключення для docker. Пропоную в цьому пості інструкцію як перемістити всі дані\nдокера в інший розділ і більше ніколи не думати про нестачу місця."), "\n", React.createElement(_components.p, null, "Перш за все потрібно зупинити сервіс докера:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> systemctl stop docker.service\n<span class=\"token function\">sudo</span> systemctl stop docker.socket</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "(можливо буде достатньо просто ", React.createElement(_components.code, null, "sudo systemctl stop docker"), ")"), "\n", React.createElement(_components.p, null, "Редагуємо конфігурацію ", React.createElement(_components.code, null, "/etc/docker/daemon.json"), " (якщо файла немає — треба його\nстворити):"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"json\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-json line-numbers\"><code class=\"language-json\"><span class=\"token punctuation\">{</span>\n  <span class=\"token property\">\"data-root\"</span><span class=\"token operator\">:</span> <span class=\"token string\">\"/new/path/to/docker-data-root\"</span>\n<span class=\"token punctuation\">}</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Я у себе вирішив скласти усе в директорію ", React.createElement(_components.code, null, "/home/{USER_NAME}/.docker/data"), ". Цю\nдиректорію автоматично створює докер після встановлення. В ній можуть бути\nдодаткові конфіги і дані для докера. Отже ця директорія виглядає як чудовий\nкандидат для переносу туди всіх інших даних докера."), "\n", React.createElement(_components.p, null, "Далі копіюємо дані зі старої локації в нову:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">rsync</span> -aP /var/lib/docker/ /new/path/to/docker-data-root</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "або через ", React.createElement(_components.code, null, "cp"), ":"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">cp</span> -axT /var/lib/docker /new/path/to/docker-data-root</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Можна ще змінити назву старої директорії, щоб під час тесту бути впевненим, що\nдокер туди точно не полізе:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">mv</span> /var/lib/docker /var/lib/docker.old</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Запускаємо усе назад:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> systemctl start docker.service\n<span class=\"token function\">sudo</span> systemctl start docker.socket</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Перевіряємо, що докер подружився з новою локацією даних:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">docker</span> images</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "можливо тут знадобиться викликати ", React.createElement(_components.code, null, "sudo docker images"), ", якщо у вас не\nналаштований\n", React.createElement(_components.a, {
    href: "https://docs.docker.com/engine/security/rootless/"
  }, "rootless docker")), "\n"), "\n", React.createElement(_components.p, null, "Якщо все працює — звільняємо місце в root:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">rm</span> -rf /var/lib/docker.old</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Ну що ж, тепер одним пожирачем місця в root менше 😉"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
